import { ParsedUrlQueryInput } from 'querystring';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { Button, FocusRingVariants, FontWeights } from '@calm-web/design-system';

import Image from '@/components/image/NextImage';
import { useAuthModalContext } from '@/context/AuthModalContextProvider';
import { useAnalytics } from '@/hooks/analytics';
import { useUserEligibility } from '@/hooks/user/useUserEligibility';
import { useAmplitudeExperiment } from '@/hooks/utils/experiments/useAmplitudeExperiment';
import { useRouterPush } from '@/hooks/utils/useRouterPush';
import { setAuthFormMode } from '@/store/authFormMode/actions';

import messages from './messages';
import {
	AppSection,
	Body,
	ButtonContainer,
	Heading,
	HomepageHeroLoader,
	JasperLake,
	StickyCopy,
	Wrapper,
} from './styles';

const Hero: React.FC = () => {
	const dispatch = useDispatch();
	const routerPush = useRouterPush();
	const { logEvent } = useAnalytics();
	const { formatMessage } = useIntl();
	const { isLoggedOut, isLoggedInEligibleToSubscribe, isLoggedInValid } = useUserEligibility();
	const { setIsModalOpen } = useAuthModalContext();
	const { isEnrolled, isLoading } = useAmplitudeExperiment('www-homepage-design-update');
	const { isEnrolled: isFTUESignupEnrolled } = useAmplitudeExperiment('www-skip-questionnaire');

	const bodyMessage = isEnrolled ? messages.bodyShort : messages.body;
	const jasperLakeImg = isEnrolled
		? '/_n/images/jasper-lake.webp'
		: '/_n/images/homepage/jasper-lake-hero-banner.webp';

	const getButtonProperties = (): [
		label: string,
		href: { pathname: string; queryParams?: ParsedUrlQueryInput },
	] => {
		if (isLoggedInValid) {
			return [formatMessage(messages.ctaActiveSub), { pathname: '/app' }];
		}

		if (isLoggedInEligibleToSubscribe) {
			return [formatMessage(messages.ctaLoggedInIneligible), { pathname: '/subscribe' }];
		}

		return [
			formatMessage(messages.cta),
			{
				pathname: '/signup-flow',
				queryParams: { source: 'homepage', ...(isFTUESignupEnrolled && { utm_content: 'organic_ft-sf' }) },
			},
		];
	};

	const [label, href] = getButtonProperties();

	const onClick = async () => {
		logEvent({
			eventName: 'Homepage : CTA : Clicked',
			eventProps: {
				section: 'hero',
			},
		});
		// Used in UA Tracking
		logEvent({
			eventName: 'Landing Page : Signup Flow CTA : Clicked',
		});
		await routerPush(href.pathname, href.queryParams);
	};

	const onSecondaryClick = async () => {
		logEvent({
			eventName: 'Homepage : CTA : Clicked',
			eventProps: {
				section: 'log in',
			},
		});

		dispatch(setAuthFormMode('login'));
		if (setIsModalOpen) {
			setIsModalOpen(true);
		}
	};

	return isLoading ? (
		<HomepageHeroLoader isLoading />
	) : (
		<Wrapper isFullHeight={isEnrolled}>
			<JasperLake>
				<Image priority alt="" loading="eager" layout="fill" objectFit="cover" src={jasperLakeImg} />
			</JasperLake>
			<AppSection>
				<StickyCopy>
					<Heading>{formatMessage(messages.heading)}</Heading>
					<Body isShortVersion={isEnrolled}>{formatMessage(bodyMessage)}</Body>
					<ButtonContainer>
						<Button
							fullWidth
							onPress={onClick}
							maxWidth="335px"
							fontWeight={FontWeights.Demi}
							backgroundColor="accessibleBrandGradient"
							textColor="white"
							focusRingVariant={FocusRingVariants.Dark}
						>
							{label}
						</Button>
						{isEnrolled && isLoggedOut && (
							<Button
								fullWidth
								onPress={onSecondaryClick}
								maxWidth="335px"
								fontWeight={FontWeights.Demi}
								backgroundColor="buttonSecondaryBg"
								textColor="buttonSecondaryText"
								borderColor="blackAlpha20"
							>
								{formatMessage(messages.secondaryCta)}
							</Button>
						)}
					</ButtonContainer>
				</StickyCopy>
			</AppSection>
		</Wrapper>
	);
};

export default Hero;
