import { announce } from '@react-aria/live-announcer';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import messages from './messages';
import { LoaderInner, LoaderOuter, Wrapper } from './styles';

type LoaderProps = {
	isLoading: boolean;
	announcement?: string;
	className?: string;
};

const HomepageLoader = ({ announcement = 'Loading', isLoading, className }: LoaderProps) => {
	const { formatMessage } = useIntl();

	useEffect(() => {
		announce(announcement, 'polite');
	}, [announcement]);

	return (
		<Wrapper
			role="status"
			aria-label={formatMessage(messages.loaderLabel)}
			$isLoading={isLoading}
			className={className}
		>
			<LoaderOuter>
				<LoaderInner />
			</LoaderOuter>
		</Wrapper>
	);
};
export default HomepageLoader;
